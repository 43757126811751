var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("img", {
      staticClass: "banner",
      attrs: { src: require("@/assets/images/banner.png") },
    }),
    _c(
      "div",
      { staticClass: "order-info" },
      [
        _c("div", { staticClass: "order-info-title border flex f-ai-c" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/icon-1.png") },
          }),
          _c("span", { staticClass: "title" }, [_vm._v("就诊信息")]),
          _c("span", { staticClass: "tips t1" }, [_vm._v("(必填)")]),
        ]),
        _c(
          "van-form",
          { ref: "form", attrs: { "validate-first": "" } },
          [
            _c("van-field", {
              attrs: {
                label: "姓名",
                placeholder: "请输入姓名",
                required: "",
                rules: _vm.nameRul,
                clearable: "",
              },
              model: {
                value: _vm.dataForm.name,
                callback: function ($$v) {
                  _vm.$set(_vm.dataForm, "name", $$v)
                },
                expression: "dataForm.name",
              },
            }),
            _c("van-field", {
              attrs: {
                label: "手机号",
                maxlength: 11,
                required: "",
                placeholder: "请输入手机号",
                rules: _vm.mobilePhoneRul,
                clearable: "",
              },
              model: {
                value: _vm.dataForm.mobilePhone,
                callback: function ($$v) {
                  _vm.$set(_vm.dataForm, "mobilePhone", $$v)
                },
                expression: "dataForm.mobilePhone",
              },
            }),
            _c("van-field", {
              attrs: {
                label: "身份证",
                maxlength: 18,
                required: "",
                placeholder: "请输入身份证号",
                rules: _vm.idCardRul,
                clearable: "",
              },
              model: {
                value: _vm.dataForm.idcard,
                callback: function ($$v) {
                  _vm.$set(_vm.dataForm, "idcard", $$v)
                },
                expression: "dataForm.idcard",
              },
            }),
            _c("van-field", {
              staticClass: "marital-status",
              attrs: { name: "radio", label: "婚姻状况", required: "" },
              scopedSlots: _vm._u([
                {
                  key: "input",
                  fn: function () {
                    return [
                      _c(
                        "van-radio-group",
                        {
                          attrs: {
                            direction: "horizontal",
                            "icon-size": "16px",
                          },
                          model: {
                            value: _vm.dataForm.maritalStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "maritalStatus", $$v)
                            },
                            expression: "dataForm.maritalStatus",
                          },
                        },
                        [
                          _c("van-radio", { attrs: { name: "1" } }, [
                            _vm._v("未婚"),
                          ]),
                          _c("van-radio", { attrs: { name: "2" } }, [
                            _vm._v("已婚"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c("van-field", {
              attrs: {
                readonly: "",
                clickable: "",
                name: "datetimePicker",
                value: _vm.dataForm.visitTime,
                label: "就诊时间",
                placeholder: "请选择",
                required: "",
                "right-icon": require("@/assets/images/icon-right.png"),
                rules: _vm.visitTimeRul,
              },
              on: {
                click: function ($event) {
                  _vm.showVisitTimePicker = true
                },
              },
            }),
            _c("van-calendar", {
              attrs: {
                type: "range",
                "allow-same-day": true,
                color: "#2491FF",
              },
              on: { confirm: _vm.onVisitTimeConfirm },
              model: {
                value: _vm.showVisitTimePicker,
                callback: function ($$v) {
                  _vm.showVisitTimePicker = $$v
                },
                expression: "showVisitTimePicker",
              },
            }),
            _c("van-field", {
              attrs: {
                "is-link": "",
                readonly: "",
                label: "就诊医院",
                placeholder: "请选择",
                required: "",
                "right-icon": require("@/assets/images/icon-right.png"),
                rules: _vm.hospitalRul,
              },
              on: {
                click: function ($event) {
                  _vm.showHospital = true
                },
              },
              model: {
                value: _vm.dataForm.hospital,
                callback: function ($$v) {
                  _vm.$set(_vm.dataForm, "hospital", $$v)
                },
                expression: "dataForm.hospital",
              },
            }),
            _c(
              "van-popup",
              {
                attrs: { round: "", position: "bottom" },
                model: {
                  value: _vm.showHospital,
                  callback: function ($$v) {
                    _vm.showHospital = $$v
                  },
                  expression: "showHospital",
                },
              },
              [
                _c("van-cascader", {
                  attrs: {
                    title: "就诊医院",
                    options: _vm.options,
                    "active-color": "#2491FF",
                  },
                  on: {
                    close: function ($event) {
                      _vm.showHospital = false
                    },
                    change: _vm.onChange,
                    finish: _vm.onFinish,
                  },
                  model: {
                    value: _vm.hospitalValue,
                    callback: function ($$v) {
                      _vm.hospitalValue = $$v
                    },
                    expression: "hospitalValue",
                  },
                }),
              ],
              1
            ),
            _c("van-field", {
              attrs: {
                "is-link": "",
                readonly: "",
                label: "就诊科室",
                placeholder: "请选择",
                required: "",
                "right-icon": require("@/assets/images/icon-right.png"),
                rules: _vm.deptRul,
              },
              on: {
                click: function ($event) {
                  _vm.showDeptPicker = true
                },
              },
              model: {
                value: _vm.dataForm.visitDept,
                callback: function ($$v) {
                  _vm.$set(_vm.dataForm, "visitDept", $$v)
                },
                expression: "dataForm.visitDept",
              },
            }),
            _c(
              "van-popup",
              {
                attrs: { round: "", position: "bottom" },
                model: {
                  value: _vm.showDeptPicker,
                  callback: function ($$v) {
                    _vm.showDeptPicker = $$v
                  },
                  expression: "showDeptPicker",
                },
              },
              [
                _c("van-cascader", {
                  attrs: {
                    title: "就诊科室",
                    options: _vm.depts,
                    "active-color": "#2491FF",
                  },
                  on: {
                    close: function ($event) {
                      _vm.showDeptPicker = false
                    },
                    change: _vm.onDeptChange,
                    finish: _vm.onDeptFinish,
                  },
                  model: {
                    value: _vm.deptValue,
                    callback: function ($$v) {
                      _vm.deptValue = $$v
                    },
                    expression: "deptValue",
                  },
                }),
              ],
              1
            ),
            _c("van-field", {
              attrs: {
                readonly: "",
                clickable: "",
                name: "picker",
                value: _vm.dataForm.serviceType,
                label: "服务类型",
                placeholder: "请选择",
                required: "",
                "right-icon": require("@/assets/images/icon-right.png"),
                rules: _vm.serviceTypeRul,
              },
              on: {
                click: function ($event) {
                  _vm.showServiceTypePicker = true
                },
              },
            }),
            _c(
              "van-popup",
              {
                attrs: { position: "bottom" },
                model: {
                  value: _vm.showServiceTypePicker,
                  callback: function ($$v) {
                    _vm.showServiceTypePicker = $$v
                  },
                  expression: "showServiceTypePicker",
                },
              },
              [
                _c("van-picker", {
                  attrs: { "show-toolbar": "", columns: _vm.serviceTypeList },
                  on: {
                    confirm: _vm.onServiceTypeConfirm,
                    cancel: function ($event) {
                      _vm.showServiceTypePicker = false
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "order-info" }, [
      _c("div", { staticClass: "order-info-title border flex f-ai-c" }, [
        _c("img", {
          staticClass: "icon",
          attrs: { src: require("@/assets/images/icon-2.png") },
        }),
        _c("span", { staticClass: "title" }, [_vm._v("证件上传")]),
      ]),
      _c("div", { staticClass: "border card-border" }, [
        _c("span", { staticClass: "card-title" }, [_vm._v("身份证正反面")]),
        _c("div", { staticClass: "flex f-jc-sb" }, [
          _c(
            "div",
            { staticClass: "upload-style-container flex f-jc-c f-ai-c" },
            [
              _c("input", {
                staticClass: "img-field",
                attrs: { type: "file", id: "cardImg1" },
                on: { change: _vm.fileChange },
              }),
              !_vm.dataForm.cardImg1
                ? _c(
                    "div",
                    {
                      staticClass: "upload-style flex f-d-c f-ai-c f-jc-c",
                      on: {
                        click: function ($event) {
                          return _vm.fileClick(1)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/camaro.png"),
                          alt: "",
                        },
                      }),
                      _c("span", [_vm._v("点击上传人像面")]),
                    ]
                  )
                : _c("img", {
                    staticClass: "img-preview",
                    attrs: { src: _vm.dataForm.cardImg1.url, alt: "" },
                    on: {
                      click: function ($event) {
                        return _vm.fileClick(1)
                      },
                    },
                  }),
            ]
          ),
          _c(
            "div",
            { staticClass: "upload-style-container flex f-jc-c f-ai-c" },
            [
              _c("input", {
                staticClass: "img-field",
                attrs: { type: "file", id: "cardImg2" },
                on: { change: _vm.fileChange },
              }),
              !_vm.dataForm.cardImg2
                ? _c(
                    "div",
                    {
                      staticClass: "upload-style flex f-d-c f-ai-c f-jc-c",
                      on: {
                        click: function ($event) {
                          return _vm.fileClick(2)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/camaro.png"),
                          alt: "",
                        },
                      }),
                      _c("span", [_vm._v("点击上传人像面")]),
                    ]
                  )
                : _c("img", {
                    staticClass: "img-preview",
                    attrs: { src: _vm.dataForm.cardImg2.url, alt: "" },
                    on: {
                      click: function ($event) {
                        return _vm.fileClick(2)
                      },
                    },
                  }),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "border card-border" }, [
        _c("span", { staticClass: "card-title" }, [_vm._v("医保卡正反面")]),
        _c("div", { staticClass: "flex f-jc-sb" }, [
          _c(
            "div",
            { staticClass: "upload-style-container flex f-jc-c f-ai-c" },
            [
              _c("input", {
                staticClass: "img-field",
                attrs: { type: "file", id: "cardImg3" },
                on: { change: _vm.fileChange },
              }),
              !_vm.dataForm.cardImg3
                ? _c(
                    "div",
                    {
                      staticClass: "upload-style flex f-d-c f-ai-c f-jc-c",
                      on: {
                        click: function ($event) {
                          return _vm.fileClick(3)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/camaro.png"),
                          alt: "",
                        },
                      }),
                      _c("span", [_vm._v("点击上传人像面")]),
                    ]
                  )
                : _c("img", {
                    staticClass: "img-preview",
                    attrs: { src: _vm.dataForm.cardImg3.url, alt: "" },
                    on: {
                      click: function ($event) {
                        return _vm.fileClick(3)
                      },
                    },
                  }),
            ]
          ),
          _c(
            "div",
            { staticClass: "upload-style-container flex f-jc-c f-ai-c" },
            [
              _c("input", {
                staticClass: "img-field",
                attrs: { type: "file", id: "cardImg4" },
                on: { change: _vm.fileChange },
              }),
              !_vm.dataForm.cardImg4
                ? _c(
                    "div",
                    {
                      staticClass: "upload-style flex f-d-c f-ai-c f-jc-c",
                      on: {
                        click: function ($event) {
                          return _vm.fileClick(4)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/camaro.png"),
                          alt: "",
                        },
                      }),
                      _c("span", [_vm._v("点击上传人像面")]),
                    ]
                  )
                : _c("img", {
                    staticClass: "img-preview",
                    attrs: { src: _vm.dataForm.cardImg4.url, alt: "" },
                    on: {
                      click: function ($event) {
                        return _vm.fileClick(4)
                      },
                    },
                  }),
            ]
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "button flex f-jc-c",
        attrs: { "native-type": "submit" },
        on: { click: _vm.commit },
      },
      [
        _c("img", {
          attrs: { src: require("@/assets/images/button.png"), alt: "" },
        }),
        _c("span", [_vm._v("提交")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }